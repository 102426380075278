import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';

interface Story {
    id: number;
    story_title: string;
    story: string;
    audio_url?: string;
}

const StoryComponent = () => {
    const {storyId} = useParams();
    const [story, setStory] = useState<Story | null>(null);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetch(`${baseURL}/api/stories/${storyId}`)
            .then(response => response.json())
            .then(data => setStory(data))
            .catch(error => console.error("There was an error!", error));
    }, [storyId]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            localStorage.setItem(`scrollPosition-${storyId}`, String(scrollPosition));
        };

        window.addEventListener('scroll', handleScroll);

        const savedScrollPosition = localStorage.getItem(`scrollPosition-${storyId}`);
        if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition));
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [storyId]);

    const countWords = (text) => {
        return text ? text.split(/\s+/).filter(Boolean).length : 0;
    };

    const calculatePages = (wordCount) => {
        const wordsPerPage = 250;
        return Math.ceil(wordCount / wordsPerPage);
    };

    const calculateReadingTime = (wordCount) => {
        const wordsPerMinute = 200;
        return Math.ceil(wordCount / wordsPerMinute);
    };

    if (story === null) {
        return <div>Loading...</div>;
    }

    const wordCount = countWords(story.story);
    const estimatedPages = calculatePages(wordCount);
    const estimatedReadingTime = calculateReadingTime(wordCount);

    const renderParagraph = ({ children }) => {
        return <p style={{ backgroundColor: 'inherit', color: 'inherit' }}>{children}</p>;
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-2">
                    <div className="text-muted mt-3">
                        <p>Word Count: {wordCount}</p>
                        <p>Estimated Pages: {estimatedPages}</p>
                        <p>Estimated Reading Time: {estimatedReadingTime} minutes</p>
                    </div>
                </div>
                <div className="col-md-8 col-sm-12">
                    <Card>
                        <CardHeader>
                            <CardTitle>{story.story_title}</CardTitle>
                            {story.audio_url && (
                                <div className="text-center mt-4">
                                    <audio controls>
                                        <source src={`${baseURL}/${story.audio_url}`} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            )}
                        </CardHeader>
                        <CardBody>
                            <div key={story.id}>
                                <ReactMarkdown
                                    components={{
                                        p: renderParagraph,
                                    }}
                                >
                                    {story.story}
                                </ReactMarkdown>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className={"col-md-2 d-none d-md-block"}/>
            </div>
        </div>
    );
}

export default StoryComponent;
