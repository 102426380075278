import React from 'react';
import logo from './logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import StoryList from "./Components/StoryList";
import Story from "./Components/Story";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path={"/"} element={<StoryList/>}/>
                    <Route path={"/story/:storyId"} element={<Story/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
