import { formatDistanceToNow } from 'date-fns';

const TimeAgo = ({ timestamp }) => {
    // Convert the timestamp string to a Date object

    if (!timestamp) {
        return <span></span>
    }

    const date = new Date(timestamp);

    // Get the time difference in a human-readable format
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });

    return <span>{timeAgo}</span>;
};

export default TimeAgo;